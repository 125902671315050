import { FetchMarkersInput } from "../interfaces/FetchMarkersInput";
import { MarkersResult } from "../interfaces/MarkersResult";
import { ApiClient, calculateDistanceBetween } from "./Utilities";

const DEFAULT_RADIUS = 100000;
const DEFAULT_MAX_RADIUS = DEFAULT_RADIUS;
const DEFAULT_SIZE = 1000;

export class MarkerService {
  public constructor(
    private serviceName: string,
    private lastInput?: FetchMarkersInput,
    private lastResult?: MarkersResult,
    private lastRadius?: number
  ) {}

  public async FetchMarkers(
    input: FetchMarkersInput | undefined,
    overrideStoresLimit = false
  ): Promise<MarkersResult> {
    try {
      if (!input) return { stores: [] };

      if (
        this.lastInput &&
        this.lastResult &&
        calculateDistanceBetween(input, this.lastInput) <
          (this.lastRadius || DEFAULT_RADIUS) / 10
      )
        return this.lastResult;

      let params = {
        ...input,
        product: input.product.toUpperCase(),
        radius: input.radius ? input.radius : DEFAULT_RADIUS,
        size: DEFAULT_SIZE,
      };
      let result = await ApiClient.get<MarkersResult>("fetch/stores", {
        params,
      });

      const storesLimit = overrideStoresLimit ? 1 : 100;

      while (
        result.data.stores.length < storesLimit &&
        params.radius < DEFAULT_MAX_RADIUS
      ) {
        params = { ...params, radius: params.radius * 10 };
        result = await ApiClient.get<MarkersResult>("fetch/stores", {
          params,
        });
      }

      this.lastInput = input;
      this.lastResult = result.data;
      this.lastRadius = params.radius;
      return result.data;
    } catch (error) {
      console.error(`[${this.serviceName}] `, "error:", error);
      return { stores: [] };
    }
  }
}
