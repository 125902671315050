import {Suspense, lazy} from "react";
import CookieConsent from "./components/CookieConsent";
import LocationDetails from "./components/LocationDetails";
import {APIKeysService} from "./services/APIKeysService";
import {calculateDistanceBetween} from "./services/Utilities";
import {ClosestPointModal} from "./components/ClosestPointModal";
import {Cookies, CookiesProvider, useCookies} from "react-cookie";
import {GoogleMapsWrapper} from "./components/MapWrapper";
import {
    COOKIES_CONSENT_COOKIE,
    DISABLE_LOCATION_CONSENT,
    LOCATION_CONSENT_COOKIE,
    PRODUCT_DETAILS,
    SHOW_QUERY_DEBUG,
} from "./Constants";
import {LocationConsent} from "./components/LocationConsent";
import {LocationService} from "./services/LocationService";
import {MapAutocomplete} from "./components/MapAutocomplete";
import {Marker} from "./interfaces/MarkersResult";
import {MarkerService} from "./services/MarkerService";
import {PosDetailsService} from "./services/PosDetailsService";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "./App.css";
import "./index.css";
import CenterMapText from "./components/CenterMapText";
import ReactGA from "react-ga4";

const queryClient = new QueryClient();

const fetchMarkersService = new MarkerService("FETCH_MARKERS");

function App() {
    const {t} = useTranslation();
    const [cookies] = useCookies([
        LOCATION_CONSENT_COOKIE,
        COOKIES_CONSENT_COOKIE,
    ]);
    const [center, setCenter] = useState<google.maps.LatLngLiteral>();
    // const [locationData, setLocationData] = useState<LocationDetailsInterface>();
    const [selectedMarker, setSelectedMarker] =
        useState<google.maps.LatLngLiteral>();
    const [posDetailsService, setPosDetailsService] =
        useState<PosDetailsService>();
    const [map, setMap] = useState<google.maps.Map>();
    const [showImageFull, setShowImageFull] = useState<boolean>(false);
    const [closestMarkers, setClosestMarkers] = useState<Marker[]>([]);
    const [showPermissionText, setShowPermissionText] = useState(false);
    const currentLocations = useRef<Array<google.maps.LatLngLiteral>>([]);
    const GoogleMaps = lazy(() => import('./components/Map'));

    const {data: apiKeys} = useQuery(
        {
            queryKey: ["FETCH_API_KEYS"],
            queryFn: APIKeysService.fetchKeys,
        },
        queryClient
    );

    const {
        data: {currentLocation: userLocation, geolocationPermissionsResult} = {
            geolocationPermissionsResult: "REJECTED",
        },
    } = useQuery(
        {
            queryKey: ["FETCH_LOCATION", cookies.LocationConsent],
            queryFn: () =>
                LocationService.GetCurrentLocation(
                    cookies.LocationConsent,
                    apiKeys?.googleApiKey ?? ""
                ),
            enabled: cookies.LocationConsent !== undefined && !!apiKeys?.googleApiKey,
            refetchInterval: 1000,
        },
        queryClient
    );

    const {
        data: {stores: markers} = {stores: []},
        isLoading: markersLoading,
    } = useQuery(
        {
            queryKey: ["FETCH_MARKERS", center],
            queryFn: () =>
                fetchMarkersService.FetchMarkers(
                    center && {
                        ...center,
                        product: PRODUCT_DETAILS.name,
                    }
                ),
        },
        queryClient
    );

    const foundMarker = markers.find(
        (marker) =>
            marker.lat === selectedMarker?.lat && marker.lng === selectedMarker?.lng
    );

    const {data: PosDetails, isLoading: posDetailsLoading} = useQuery(
        {
            queryKey: ["FETCH_LOCATION_DETAILS", foundMarker],
            queryFn: () => posDetailsService?.fetchPosDetails(foundMarker),
            enabled: !!posDetailsService && !!foundMarker,
        },
        queryClient
    );

    useEffect(() => {
        if (!center) {
            if (userLocation) {
                setCenter(userLocation);
            }
        }
    }, [center, userLocation]);

    if (cookies[COOKIES_CONSENT_COOKIE]) {
        process.env.REACT_APP_ANALYTICS_ID &&
        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "Store locator",
        });
    }

    useEffect(() => {
        if (!markersLoading && !closestMarkers.length) setClosestMarkers(markers);
    }, [closestMarkers.length, markers, markersLoading]);

    const onCenterChange = (newCenter: google.maps.LatLngLiteral) => {
        setCenter(newCenter);
    };

    useEffect(() => {
        document.title = t("pageTitle", {product: PRODUCT_DETAILS.description});

        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = PRODUCT_DETAILS.pinSVG;
    }, [t]);
    const locations = useMemo(
        () =>
            !markersLoading
                ? markers.map((value) => ({
                    lat: value.lat,
                    lng: value.lng,
                }))
                : currentLocations.current,
        [markers, markersLoading]
    );
    currentLocations.current = locations;

    const closestPoint = userLocation
        ? LocationService.GetClosestPoint(userLocation, closestMarkers)
        : undefined;
    const closestMarker = closestMarkers.find(
        (marker) =>
            marker.lat === closestPoint?.lat && marker.lng === closestPoint?.lng
    );
    const closestPointDistance =
        userLocation && closestPoint
            ? calculateDistanceBetween(userLocation, closestPoint)
            : undefined;

    const onMarkerClick = (data: google.maps.LatLngLiteral) => {
        setSelectedMarker(data);
        setShowImageFull(false);
    };

    const onMapLoaded = (map: google.maps.Map) => {
        setMap(map);
        setPosDetailsService(new PosDetailsService(map));
    };

    const initialCookies = useMemo(() => {
        const cookies = new Cookies();
        cookies.set(LOCATION_CONSENT_COOKIE, DISABLE_LOCATION_CONSENT || false);
        return cookies;
    }, []);

    return (
        <div className="App">
            <CookiesProvider allCookies={initialCookies}>
                <GoogleMapsWrapper apiKey={apiKeys?.googleApiKey}>
                    {(!posDetailsLoading || PosDetails !== undefined) && (
                        <LocationDetails
                            locationData={
                                foundMarker && {
                                    ...foundMarker,
                                    ...PosDetails,
                                    name: foundMarker?.name ?? PosDetails?.name ?? "",
                                }
                            }
                            showLocationDetails={!!selectedMarker}
                            onClose={() => setSelectedMarker(undefined)}
                            showFullImage={showImageFull}
                            setShowFullImage={(x: boolean) => setShowImageFull(x)}
                        />
                    )}
                    <MapAutocomplete map={map}/>
                    <Suspense fallback={<></>}>
                        <GoogleMaps
                            locations={locations}
                            center={center}
                            userLocation={userLocation}
                            onCenterChange={onCenterChange}
                            onMarkerClick={onMarkerClick}
                            onMapLoaded={onMapLoaded}
                            map={map}
                            mapId={apiKeys?.googleMapsId}
                            locationPermission={geolocationPermissionsResult}
                            setShowPermissionText={(x: boolean) => setShowPermissionText(x)}
                        />
                    </Suspense>
                </GoogleMapsWrapper>
                {userLocation ? (
                    <ClosestPointModal
                        point={closestMarker}
                        distance={closestPointDistance}
                        map={map}
                        closeTab={() => setSelectedMarker(undefined)}
                    />
                ) : null}
                <CookieConsent/>
                <LocationConsent/>
                {SHOW_QUERY_DEBUG ? (
                    <ReactQueryDevtools
                        initialIsOpen={false}
                        client={queryClient}
                        buttonPosition="bottom-left"
                    />
                ) : null}
            </CookiesProvider>
            <CenterMapText show={showPermissionText}/>
        </div>
    );
}

export default App;
