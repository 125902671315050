import Axios from "axios";
import { LocationResult } from "../interfaces/LocationResult";
import { calculateDistanceBetween } from "./Utilities";

export interface CurrentLocationType {
  currentLocation: google.maps.LatLngLiteral;
  geolocationPermissionsResult: "ACCEPTED" | "REJECTED";
}
export class LocationService {
  static GetCurrentLocation(
    withPermission: boolean,
    geolocationKey: string
  ): Promise<CurrentLocationType> {
    const fetchOnline = (
      resolve: (value: google.maps.LatLngLiteral) => void,
      reject: (reason: Error) => void
    ) =>
      Axios.post<LocationResult>(
        "https://www.googleapis.com/geolocation/v1/geolocate",
        {},
        { params: { key: geolocationKey } }
      )
        .then((result) =>
          resolve({
            lat: result.data.location.lat,
            lng: result.data.location.lng,
          })
        )
        .catch(reject);

    return new Promise((resolve, reject) => {
      if (withPermission && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) =>
            resolve({
              currentLocation: {
                lat: coords.latitude,
                lng: coords.longitude,
              },
              geolocationPermissionsResult: "ACCEPTED",
            }),
          (positionError) => {
            if (
              positionError.code === GeolocationPositionError.PERMISSION_DENIED
            ) {
              fetchOnline(
                (result) =>
                  resolve({
                    currentLocation: result,
                    geolocationPermissionsResult: "REJECTED",
                  }),
                reject
              );
            } else {
              reject(positionError);
            }
          },
          { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
        );
      } else {
        fetchOnline(
          (result) =>
            resolve({
              currentLocation: result,
              geolocationPermissionsResult: "REJECTED",
            }),
          reject
        );
      }
    });
  }

  static GetClosestPoint(
    center: google.maps.LatLngLiteral,
    locations: ReadonlyArray<google.maps.LatLngLiteral>
  ) {
    return locations.reduce(
      (acc, current) =>
        calculateDistanceBetween(acc, center) <
        calculateDistanceBetween(current, center)
          ? acc
          : current,
      locations[0]
    );
  }
}
